/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Sevarek";
  src: url("assets/fonts/seravek.ttf");
}

html,
body {
  background-color: #FBFBFB !important;
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.form-title {
  min-width: 400px;
  margin: auto;
  text-align: center;
  font-size: 48px;
  font-family: "Sevarek", sans-serif;
  font-weight: 400;
  line-height: 65px;
}

.form-subtitle {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.mt {
  margin-top: 1rem;
}

.mt-large {
  margin-top: 4rem;
}

.mb {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.practitioner-change-password-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: visible;
}

.pointer-event {
  cursor: pointer;
}
